import React from "react";

const CustomerSm = ({ img, comment, name, mt }) => {
  return (
    <div className="customer-sm">
      {" "}
      <img className="img-fluid col-6" src={img} alt="" />
      <p>{comment}</p>
      <h4>{name}</h4>
    </div>
  );
};

export default CustomerSm;
