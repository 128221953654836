import React from "react";
import Customer from "./Customer";
import sourabh from "./../Assets/images/sourabh.png";
import reshma from "./../Assets/images/reshma.png";
import dhruv from "./../Assets/images/dhruv-sharma.png";
import kunal from "./../Assets/images/kunal-bhalla.png";
import CustomerSm from "./CustmerSm";
import ReactOwlCarousel from "react-owl-carousel";

const Testimonials = () => {
  return (
    <div className="container-fluid my-5 px-5" id="testimonials">
      <h1 className="text-center mt-2 p-4">Testimonials</h1>
      <div className="d-none d-md-block mt-5">
        <div className="row row-cols-md-3 row-cols-lg-4 justify-content-between p-2 mx-3">
          <Customer
            img={dhruv}
            comment="It's my initial days at the academy however I think I am at the right place and in right hands per se the coach Pulkit. I am a working professional and wanted to pursue the game.... "
            name="Dhruv Sharma"
          />
          <Customer
            img={sourabh}
            comment="All credit goes for this academy is to Mr. Pulkit who is the main coach of this academy.
            This guy take your game to next level"
            name="Sourabh Grover"
          />
          <Customer img={kunal} comment="Best!" name="Kunal Bhalla" />
          <Customer
            img={reshma}
            comment="The overall experience is commendable. Pulkit sir as a coach has good knowledge and vast experience about the game."
            name="Reshma Nair"
          />
        </div>
      </div>
      <div className="d-md-none">
        <ReactOwlCarousel
          items={1}
          autoplay
          autoplayTimeout={2000}
          loop
          autoplayHoverPause
        >
          <CustomerSm
            img={dhruv}
            comment="It's my initial days at the academy however I think I am at the right place and in right hands per se the coach Pulkit. I am a working professional and wanted to pursue the game.... "
            name="Dhruv Sharma"
          />
          <CustomerSm
            img={sourabh}
            comment="All credit goes for this academy is to Mr. Pulkit who is the main coach of this academy.
            This guy take your game to next level"
            name="Sourabh Grover"
          />
          <CustomerSm img={kunal} comment="Best!" name="Kunal Bhalla" />
          <CustomerSm
            img={reshma}
            comment="The overall experience is commendable. Pulkit sir as a coach has good knowledge and vast experience about the game."
            name="Reshma Nair"
          />
        </ReactOwlCarousel>
      </div>

      <div className="text-center">
        <a
          href="https://www.justdial.com/Chandigarh/Positive-Table-Tennis-Academy-Zirakpur-HO/0172PX172-X172-160802165751-B8S8_BZDET"
          className="btn btn-gradient"
          target="_blank"
          rel="noreferrer"
        >
          <h6 className="mb-0">More Reviews</h6>
        </a>
      </div>
    </div>
  );
};

export default Testimonials;
