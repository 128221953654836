import React from "react";
import { Link } from "react-router-dom";
import logo from "./../Assets/images/logo.png";


const Navbar = () => {

  return (
    
    <nav class="navbar navbar-expand-lg bg-white fixed-top">
      <div class="container-fluid px-0 px-lg-5">
      
      
        <Link
          class="navbar-brand col-8 col-lg-4 ps-3 ps-lg-0 py-0 my-0"
          id="logo"
          to="/"
        >
          <img src={logo} className="logo d-inline-block align-text-top"alt="Logo" width="82" height="80" />
          <div class="mb-2 items-center d-inline-flex flex-column">
          <a class="hheading navbar-brand" href="https://positivetabletennis.com/">Positive Table Tennis Academy</a>
          <span class="lheading navbar-brand" href="https://positivetabletennis.com/">and Yoga Studio</span>
          </div>
          
        </Link>

        <button
          class="btn d-lg-none me-3"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          class="offcanvas d-lg-none offcanvas-end"
          tabindex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div class="offcanvas-header">
            <button
              type="button"
              class="btn-close ms-auto"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <ul class="navbar-nav mb-2 mb-lg-0 col-lg-10 ms-auto">
              <li class="nav-item my-1 border-bottom pb-2" data-bs-dismiss="offcanvas">
                <Link to="/" class="px-4" aria-current="page">
                  Home
                </Link>
              </li>
              <li class="nav-item my-1 border-bottom pb-2" data-bs-dismiss="offcanvas">
                <Link to="/about" class="px-4">
                  About Us
                </Link>
              </li>
              <li class="nav-item my-1 border-bottom pb-2">
                <a href="/#contact" class="px-4">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="collapse navbar-collapse" id="navbarText">
          <ul class="navbar-nav mb-2 mb-lg-0 col-lg-10 ms-auto">
            <li class="nav-item">
              <Link to="/" class="nav-link px-4" aria-current="page">
                Home
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/about" class="nav-link px-4">
                About Us
              </Link>
            </li>
            <li class="nav-item">
              <a href="/#contact" class="nav-link px-4">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
  
};

export default Navbar;
