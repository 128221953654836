import React from "react";

const AchievementCard = ({ id, image, name, achievement, margin,height }) => {
  return (
    <>
      <div className="achievementCard pt-4 p-3">
       <img src={image} size="auto" width="100%" height="100%" className="achievementImg" alt="" />
        <div className="ps-2 ps-lg-3 py-2 py-lg-3 bg-gradient achievementText">
          <h3 className="text-start">{name}</h3>
          <h5 className={margin}>{achievement}</h5>
        </div>
      </div>
    </>
  );
};

export default AchievementCard;
