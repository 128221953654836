import React from "react";
import banner from "./../Assets/images/banner.png";
import banner2 from "./../Assets/images/banner-2.png";
import banner3 from "./../Assets/images/banner-3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import { EffectCreative, Autoplay, Navigation } from "swiper";
import "swiper/css/autoplay";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Carousel = () => {
  return (
    <div className="mx-auto">
      <Swiper
        navigation
        autoplay={{ delay: 3000 }}
        grabCursor
        effect={"creative"}
        creativeEffect={{
          prev: {
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[EffectCreative, Autoplay, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img className="d-block w-100" src={banner3} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
          <img className="d-block w-100" src={banner2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <div className="position-relative">
            <img className="d-block w-100" src={banner} alt="" />
            <div className="position-absolute" id="carousel-text">
              <div className="col-10 col-lg-7 mx-auto text-center">
                <h3 className="fw-bold mb-3">
                  The Positive Table Tennis Academy aims at the conscious
                  induction of table tennis as a sport into the lives of
                  children.
                </h3>
               
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Carousel;
