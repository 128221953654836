import React from "react";
import eventImage from "./../Assets/images/events.png";
import rectArrow from "./../Assets/icons/arrow3.png";
import groupTraining from "./../Assets/videos/Group_Train_Final.mp4";
import physioTherapy from "./../Assets/videos/Physio_Final.mp4";
import oneTable from "./../Assets/videos/One_TAble_One _Coach.mp4";
import yogaTraining from "./../Assets/images/yoga-training.png";
import personal from "./../Assets/videos/Personal_TRaining.mp4";
import fitness from "./../Assets/videos/fitness-training.mp4";
import mentalCounselling from "./../Assets/images/priscilla-du-preez-aPa843frIzI-unsplash.jpg";
import multiballTraining from "./../Assets/videos/multiball-training.mp4";
import robot from "./../Assets/images/training-with-robot.png";
import strategyMaking from "./../Assets/images/strategy-making.png";
import cashPrizes from "./../Assets/images/cash-prizes.png";

const Events = () => {
  return (
    <div className="container-fluid p-5" id="events">
      <div class="row row-cols-1 row-cols-lg-2 align-items-start">
        <div className="ps-3 pe-0">
          <div
            class="nav flex-column bg-gradient-custom p-0 m-0 px-3 px-lg-5"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <h1>Training Concepts</h1>
            <button
              class="text-start btn border-0 text-white active"
              id="v-pills-sunday-league-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-sunday-league"
              type="button"
              role="tab"
              aria-controls="v-pills-sunday-league"
              aria-selected="true"
            >
              <li>
                <img src={rectArrow} alt="" />
                Monthly Sunday Leagues - Cash Prizes
              </li>
            </button>
            <button
              class="text-start btn border-0 text-white"
              id="v-pills-multiball-training-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-multiball-training"
              type="button"
              role="tab"
              aria-controls="v-pills-multiball-training"
              aria-selected="false"
            >
              <li>
                <img src={rectArrow} alt="" />
                Multiball Training
              </li>
            </button>

            <button
              class="text-start btn border-0 text-white"
              id="training-with-robot-tab"
              data-bs-toggle="pill"
              data-bs-target="#training-with-robot"
              type="button"
              role="tab"
              aria-controls="training-with-robot"
              aria-selected="false"
            >
              <li>
                <img src={rectArrow} alt="" />
                Training with robot
              </li>
            </button>
            <button
              class="text-start btn border-0 text-white"
              id="group-training-tab"
              data-bs-toggle="pill"
              data-bs-target="#group-training"
              type="button"
              role="tab"
              aria-controls="group-training"
              aria-selected="false"
            >
              <li>
                <img src={rectArrow} alt="" />
                Group Training
              </li>
            </button>
            <button
              class="text-start btn border-0 text-white"
              id="v-pills-personal-training-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-personal-training"
              type="button"
              role="tab"
              aria-controls="v-pills-personal-training"
              aria-selected="false"
            >
              <li>
                <img src={rectArrow} alt="" />
                Personal training
              </li>
            </button>
            <button
              class="text-start btn border-0 text-white"
              id="v-pills-one-table-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-one-table"
              type="button"
              role="tab"
              aria-controls="v-pills-one-table"
              aria-selected="false"
            >
              <li>
                <img src={rectArrow} alt="" />
                One table one coach
              </li>
            </button>
            <button
              class="text-start btn border-0 text-white"
              id="v-pills-yoga-training-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-yoga-training"
              type="button"
              role="tab"
              aria-controls="v-pills-yoga-training"
              aria-selected="false"
            >
              <li>
                <img src={rectArrow} alt="" />
                Yoga Training
              </li>
            </button>
            <button
              class="text-start btn border-0 text-white"
              id="v-pills-fitness-training-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-fitness-training"
              type="button"
              role="tab"
              aria-controls="v-pills-fitness-training"
              aria-selected="false"
            >
              <li>
                <img src={rectArrow} alt="" />
                Fitness Training
              </li>
            </button>
            <button
              class="text-start btn border-0 text-white"
              id="v-pills-physio-sessions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-physio-sessions"
              type="button"
              role="tab"
              aria-controls="v-pills-physio-sessions"
              aria-selected="false"
            >
              <li>
                <img src={rectArrow} alt="" />
                Physio Sessions
              </li>
            </button>
            <button
              class="text-start btn border-0 text-white"
              id="v-pills-mental-counselling-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-mental-counselling"
              type="button"
              role="tab"
              aria-controls="v-pills-mental-counselling"
              aria-selected="false"
            >
              <li>
                <img src={rectArrow} alt="" />
                Mental Counselling
              </li>
            </button>
            <button
              class="text-start btn border-0 text-white"
              id="v-pills-strategy-making-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-strategy-making"
              type="button"
              role="tab"
              aria-controls="v-pills-strategy-making"
              aria-selected="false"
            >
              <li>
                <img src={rectArrow} alt="" />
                Strategy Making
              </li>
            </button>
          </div>
        </div>
        <div class="tab-content pe-0 ps-3 pt-3 pt-lg-0" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-sunday-league"
            role="tabpanel"
            aria-labelledby="v-pills-sunday-league-tab"
            tabindex="0"
          >
            <img src={cashPrizes} width="100%" alt="" id="eventimg1"/>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-multiball-training"
            role="tabpanel"
            aria-labelledby="v-pills-multiball-training-tab"
            tabindex="0"
          >
            <video controls width="100%" autoPlay muted>
              <source src={multiballTraining} type="video/mp4" />
            </video>
          </div>

          <div
            class="tab-pane fade px-2"
            id="training-with-robot"
            role="tabpanel"
            aria-labelledby="v-pills-training-with-robot-tab"
            tabindex="0"
          >
            <img src={robot} width="100%" alt="" />
          </div>
          <div
            class="tab-pane fade"
            id="group-training"
            role="tabpanel"
            aria-labelledby="v-pills-group-training-tab"
            tabindex="0"
          >
            <video controls width="100%" autoPlay muted>
              <source src={groupTraining} type="video/mp4" />
            </video>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-personal-training"
            role="tabpanel"
            aria-labelledby="v-pills-personal-training-tab"
            tabindex="0"
          >
            <video controls width="100%" autoPlay muted>
              <source src={personal} type="video/mp4" />
            </video>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-one-table"
            role="tabpanel"
            aria-labelledby="v-pills-one-table-tab"
            tabindex="0"
          >
            <video controls width="100%" autoPlay muted>
              <source src={oneTable} type="video/mp4" />
            </video>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-yoga-training"
            role="tabpanel"
            aria-labelledby="v-pills-yoga-training-tab"
            tabindex="0"
          >
            <img src={yogaTraining} alt="yoga-training" width="100%" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-fitness-training"
            role="tabpanel"
            aria-labelledby="v-pills-fitness-training-tab"
            tabindex="0"
          >
            <video controls width="100%" autoPlay muted>
              <source src={fitness} type="video/mp4" />
            </video>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-physio-sessions"
            role="tabpanel"
            aria-labelledby="v-pills-physio-sessions-tab"
            tabindex="0"
          >
            <video controls width="100%" autoPlay muted>
              <source src={physioTherapy} type="video/mp4" />
            </video>
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-mental-counselling"
            role="tabpanel"
            aria-labelledby="v-pills-mental-counselling-tab"
            tabindex="0"
          >
            <img src={mentalCounselling} width="100%" alt="" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-strategy-making"
            role="tabpanel"
            aria-labelledby="v-pills-strategy-making-tab"
            tabindex="0"
          >
            <img src={strategyMaking} width="100%" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
