import React from "react";
import BannerCardsContainer from "./BannerCardsContainer";
import Carousel from "./Carousel";

const Header = () => {
  return (
    <header>
      <Carousel></Carousel>
      <BannerCardsContainer></BannerCardsContainer>
    </header>
  );
};

export default Header;
