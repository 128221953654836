import React from "react";
import Header from "./../components/Header";
import Importance from "./../components/Importance";
import TopRated from "./../components/TopRated";
import Coaches from "./../components/Coaches";
import Achievments from "./../components/Achievments";
import Events from "./../components/Events";
import Testimonials from "./../components/Testimonials";
import Contact from "./../components/Contact";
import Footer from "./../components/Footer";
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Positive Table Tennis Academy in Panchkula
        </title>
      </Helmet>
      <Header />
      <Importance />
      <TopRated />
      <Coaches />
      <Achievments />
      <Events />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
