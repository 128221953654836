import React from "react";
import uttRepresentation from "./../Assets/images/achievements/jan27_2024.jpg";
import garvita2 from "./../Assets/images/achievements/garvita-2.png";
import trishiGarvita from "./../Assets/images/achievements/trishi_garvita.jpg";
import suhani from "./../Assets/images/suhani.png";
import dhruvBedi from "./../Assets/images/achievements/dhruv_bedi.jpg";
import induKohli from "./../Assets/images/achievements/indu_kohli.jpg";
import tarundeepAndIndu from "./../Assets/images/achievements/tarundeep_and_indu.jpg";
import vidyaKumariSeth from "./../Assets/images/achievements/dec16_2023.jpg";
import nov5_2023 from "./../Assets/images/achievements/nov5_2023.jpg";
import trishi_garvita_kairav from "./../Assets/images/achievements/trishi_garvita_kairav.jpg";
import kariav_trishi_garvita from "./../Assets/images/achievements/kairav_trishi_garvita.jpg";
import oct15_2023 from "./../Assets/images/achievements/oct12_2023.jpg";
import instagram from "./../Assets/icons/instagram3.png";
import AchievementCard from "./AchievementCard";

const Achievments = () => {
  const achievemntsList = [
    // {
    //   id: "achievemnt10",
    //   img: garvita2,
    //   name: "Garvita Surana",
    //   achievement: "third position in second chandigarh state ranking",
    //   height:"370px"
    // },
    // {
    //   id: "achievemnt9",
    //   img: suhani,
    //   name: "Suhani Sareen",
    //   achievement: "won gold in inter ICSE tournament",
    //   height:"370px"
    // },
    {
      id: "achievemnt8",
      img: uttRepresentation,
      name: "Garvita Surana, Trishi Surana, and Kairav Saini",
      achievement: " Represented Chandigarh at the Utt Cadet and Sub Junior Nationals in Indore",
      height:"370px"
    },

    {
      id: "achievemnt11",
      img: vidyaKumariSeth,
      name: "Vidya Kumari Seth",
      achievement: "Secured 2nd Position in Para Khelo India games",
      height:"370px"
    },


    {
      id: "achievemnt11",
      img: nov5_2023,
      name: "Garvita, Kairav & Trishi",
      achievement: "1st position in U11 girls category, 2nd position in U13 boys category and 3rd position in U11 girls category respectively",
      height:"370px"
    },

    {
      id: "achievemnt6",
      img: oct15_2023,
      name: "Kairav, Trishi & Garvita",
      achievement: "U13 Boys Champion, U11 Girls Ace & U11 Girs Silver Star respectively",
      height:"370px"
    },

    {
      id: "achievemnt2",
      img: dhruvBedi,
      name: "Dhruv Bedi",
      achievement: "Won 2 Gold and 1 Silver in zscaler mohali table tennis tournament 2023",
      height:"370px"
    },

    {
      id: "achievemnt1",
      img: trishiGarvita,
      name: "Trishi & Garvita",
      achievement: "Trishi takes the top spot, and Garvita shines in third place at the Chandigarh State Ranking for Under 11 Girls",
      height:"370px"
    },
    
    {
      id: "achievemnt3",
      img: kariav_trishi_garvita,
      name: "Kairav Saini, Garvita Surana & Trishi Surana",
      achievement: "Gold medal in U13 boys, Silver & Bronze in U11 girls category respectively",
      height:"370px"
    },
    {
      id: "achievemnt4",
      img: induKohli,
      name: "Indu Kohli",
      achievement: "Bronze Medal in the Women's 39+ category",
      height:"370px"
    },
    {
      id: "achievemnt5",
      img: trishi_garvita_kairav,
      name: "Trishi Surana, Garvita Surana & Kairav Saini",
      achievement: "Silver Medal in U11 Girls, Bronze Medal in U11 Girls & Bronze Medal in U13 Boys category respectively",
      height:"370px"
    },

    {
      id: "achievemnt12",
      img: tarundeepAndIndu,
      name: "Tarundeep Singh and Indu Kohli",
      achievement: "Won Bronze Medal in the Haryana State table tennis ranking tournament!",
      height:"370px"
    },
  ]
  return (
    <div className="container-fluid pt-5 text-start">
      <h1 className="display-1 text-center mt-5">Achievements</h1>
      <div className="row row-cols-1 row-cols-lg-3 px-5" >
        {achievemntsList.map(item => <AchievementCard id={item.id} height="575" name={item.name} achievement={item.achievement} image={item.img}></AchievementCard>)}
      </div>
      <div className="mt-5 text-center mobile-mt-10">
        <a
          href="https://www.instagram.com/positivetabletennis/"
          className="btn-gradient"
        >
          Read More
          <img src={instagram} id="instagram" className="ms-3" alt="" />
        </a>
      </div>
    </div>
  );
};

export default Achievments;
