import React from "react";

const Customer = ({ img, comment, name }) => {
  return (
    <div className="customer border-0 card my-5">
      <div className="card-body p-0">
        <img className="img-fluid col-6" src={img} alt="" />
        <p>{comment}</p>
      </div>
      <div className="card-footer mt-0 bg-transparent border-0 p-0">
        <h4>{name}</h4>
      </div>
    </div>
  );
};

export default Customer;
