import React from "react";
import Pulkit from "./../Assets/images/mr-pulkit.png";
import InduKohli from "./../Assets/images/mrs-indu-kohli.png";

const Coaches = () => {
  return (
    <div className="container-fluid px-5" id="coaches">
      <h1 className="display-1 text-center">Coaches</h1>
      <div className="row p-4 p-lg-0">
        <div className="col-12 col-lg-6 pt-1 pb-0 bg-light d-flex align-items-center order-1 order-lg-0">
          <div className="my-auto px-lg-5 order-1 order-lg-0">
            <h3 className="text-warning fw-bold">Mr. Pulkit </h3>
            <h5 className="fw-bolder">Table Tennis Coach</h5>
            <p className="col-12 col-lg-9">
            Pulkit Arora is a certified international table tennis federation coach. He is former national player, Haryana state champion and Panchkula district champion. In the field of coaching produced many top ranked players in Chandigarh and Haryana state who has represented in national and hold their rank in nationals and still producing more and more players. He has been experience of 22 years in the field of table tennis and been 11 year as a coach, worked as a head coach of State Bank of India Punjab circle women team. Worked with schools for 9 years as table tennis coach.
            </p>
          </div>
        </div>
        <div className="col-12 col-lg-6 pt-0 mt-0">
          <img src={Pulkit} className="img-fluid" alt="" />
        </div>
      </div>

      <div className="row my-4 mt-3 p-4 p-lg-0">
        <div className="col-12 col-lg-6 px-3 px-lg-0 d-flex justify-content-center">
          <img src={InduKohli} className="img-fluid" alt="" />
        </div>
        <div className="col-12 col-lg-6">
          <div className="pt-1 pb-0 bg-light h-100 d-flex align-items-center">
            <div className="my-auto px-lg-5 order-1 order-lg-0">
              <h3 className="text-warning fw-bold">Mrs. Indu Kohli</h3>
              <h5 className="fw-bolder">Yoga Trainer</h5>
              <p className="col-12 col-lg-9">
              Indu Kohli is an international certified yoga trainer. She has done her yoga qualification with yoga alliance international and world yoga federation. She has 12 years of experience in the field as a yoga trainer. She is currently working with sports person of different sports and specialised In the sport of table tennis. She also takes personal yoga and fitness training sessions to keep her clients fit and healthy.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="coaches-2">
        <div className="row px-0 d-none d-md-flex">
          <div className="col-4 px-0">
            <div className="shadow rounded p-4 me-2">
              <h3 className="fw-bold text-warning">Pawan</h3>
              <p className="col-10">
                is a national level player and a state champion.
              </p>
            </div>
          </div>
          <div className="col-4 px-0">
            <div className="shadow rounded p-4 mx-2">
              <h3 className="fw-bold text-warning">Anmol</h3>
              <p className="col-10">
                is a national level player and a state champion.
              </p>
            </div>
          </div>
          <div className="col-4 px-0">
            <div className="shadow rounded p-4 mx-2">
              <h3 className="fw-bold text-warning">Paras</h3>
              <p className="col-10">
                is a national level player and a state champion.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coaches;
