import React from "react";

const Footer = () => {
  return (
    <footer className="text-center">
      Developed by &copy;<a href="https://banao.tech/" className="text-warning" target="_blank">Banao Technologies</a>
    </footer>
  );
};

export default Footer;



