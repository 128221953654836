import React ,{useState,useEffect} from "react";
import BannerCard from "./BannerCard";

const BannerCardsContainer = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )
  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

    

  return (
    
    <div className="banner-card-container container-fluid">
      <div className="row row-cols-2 row-cols-lg-4 px-5 cards">
        <BannerCard
          topColor="#FEAF00"
          bottomColor="#FF8713"
          header={"Register For Table Tennis Lesson"}
          description="Our experiences in-structure offer a variety of table tennis lessons for children and teens"
        ></BannerCard>
        <BannerCard
          topColor="#FEAF00"
          bottomColor="#FF8713"
          header={"Register For Yoga Session"}
          description="Our experiences in-structure offer a variety of table tennis lessons for children and teens"
        ></BannerCard>
        
        {matches &&
        <BannerCard
          topColor="#FEAF00"
          bottomColor="#FF8713"
          header={"Locate a Tennis Academy"}
          description="Our experiences in-structure offer a variety of table tennis lessons for children and teens"
        ></BannerCard>}

        {matches && 
        <BannerCard
          topColor="#FEAF00"
          bottomColor="#FF8713"
          header={"What Level Is My Child?"}
          description="Our experiences in-structure offer a variety of table tennis lessons for children and teens"
        ></BannerCard>}

      </div>
    </div>
    );
  };

export default BannerCardsContainer;
