import React from "react";
import aboutImg from "./../Assets/images/about-image.png";
import { Helmet } from "react-helmet-async";
import Contact from "./../components/Contact";

const About = () => {
  return (
    <>
    <div className="container">
      <Helmet>
        <title>
          Positive Table Tennis Academy | Professional table tennis coaching in
          Panchkula, Chandigarh
        </title>
      </Helmet>
      <div className="row justify-content-around align-items-center my-auto">
        <div className="col-12 col-lg-4">
          <img src={aboutImg} alt="" className="img-fluid mt-5" />
        </div>
        <div className="col-12 col-lg-6">
          <h4 className="About fw-bold mt-3 aligned-items-center">About us</h4>
          <p>
            Positive Table Tennis Academy was formed with the goal of positively
            improving people's lives via sport. We believe that sport enhances
            people's and ecosystems' well-being across personal and professional
            spheres and that it supports long-term Cultural, Social, and
            Economic advantages for individuals, societies, nations, and the
            world.
          </p>
          <p>
            An experienced team of former athletes, coaches, management science
            specialists, and technologists champions our purpose of assisting
            people and businesses in learning and growing through sport in all
            of its forms. Their expertise and enthusiasm are at the heart of our
            goods and services.
          </p>
          <p>
            Our values - Pride in Performance, Integrity, People-Centricity, and
            Environmental Concern - are vital to our mission.
          </p>
        </div>
      </div>
    </div>
    <Contact />
  </>
  );
};

export default About;