import React from "react";

const TopRated = () => {
  return (
    <div id="top-rated" className="mb-5">
      <div
        className="text-white col-12 col-lg-6 ms-0 ms-lg-5 pb-5 pb-lg-0"
        id="top-rated-text"
      >
        <div className="container-fluid py-4 pe-5">
          <h3 className="ps-5 pt-5 col-10">
            Why Our Positive Tennis Academy is Rated #1 in The Region
          </h3>

          <ul className="ms-3">
            <li className="my-2">
              Scientific and structured coaching for aspiring athletes, both in
              and out of school, to achieve in a sport of their choice.
            </li>
            <li className="my-2">
              Sport-centered training and mentoring for individuals.
            </li>
            <li className="my-2">
              Insights and strategies based on comprehensive consumer research
              to assist brands in maximizing the use of sports in marketing and
              internal campaigns.
            </li>
            <li className="my-2">
              Platforms and services that assist athletes and franchises in
              leveraging technology to manage their sporting careers and engage
              with their ecosystems.
            </li>
            <li className="my-2">
              Internationally renowned Table Tennis luminaries created the
              program's framework and content. The curriculum is carried out on
              the ground by qualified/certified and experienced coaches. There
              is also ample opportunity for match play as well as advice/input
              on support areas such as nutrition, physiotherapy, and mental
              conditioning.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TopRated;
