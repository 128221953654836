import React from "react";
import people from "./../Assets/icons/people.png";
import calendar from "./../Assets/icons/calendar.png";
import clock from "./../Assets/icons/clock.png";
import arrow from "./../Assets/icons/arrow2.png";

const Register = () => {
  const date = document.getElementById("date");
  // date.focus();

  return (
    <div id="register">
      <div id="register-container">
        <div className="text-white">
          <h1 className="display-1 booking-header">BOOK YOUR SLOT FOR</h1>

          <div className="row row-cols-2">
            <div className="text-end">
              <input name="slot" type="radio" id="yoga" />
              <label className="h3 ms-4" htmlFor="yoga">
                Yoga
              </label>
            </div>
            <div className="text-start">
              <input name="slot" type="radio" id="table-tennis" />
              <label className="h3 ms-4" htmlFor="table-tennis">
                Table Tennis
              </label>
            </div>
          </div>
          <div className="col-10 mx-auto">
            <div className="d-flex gap-2 flex-column flex-lg-row">
              <input
                className="form-control rounded-0"
                type="text"
                placeholder="NAME"
              />
              <input
                className="form-control rounded-0"
                type="text"
                placeholder="EMAIL ID"
              />
              <input
                className="form-control rounded-0"
                type="text"
                placeholder="CONTACT NO."
              />
            </div>
            <div className="row row-cols-1 row-cols-lg-3 mt-2 flex-column flex-lg-row">
              <div className="position-relative date">
                <input
                  type="date"
                  className="form-control py-3 rounded-0 shadow"
                  id="date"
                  placeholder="Date"
                />
                <div id="calendar">
                  <img src={calendar} alt="" />
                </div>
                <label htmlFor="#date" id="date-text" className="ms-5">
                  Date
                </label>
              </div>

              <div className="position-relative clock">
                <div id="clock">
                  <img src={clock} alt="" />
                </div>
                <>
                  <img src={arrow} className="img-fluid" id="arrow2" alt="" />
                </>
                <select
                  className="form-control py-3 rounded-0 shadow"
                  name="time"
                  id="time"
                >
                  <option value="time">Time</option>
                  <option className="text-dark" value="saab">
                    6:00 - 8:00
                  </option>
                  <option className="text-dark" value="opel">
                    8:00 - 9:00
                  </option>
                  <option className="text-dark" value="audi">
                    9:00 - 10:00
                  </option>
                </select>
                <div className="clock-arrow"></div>
              </div>
              <div className="position-relative people">
                <div id="people">
                  <img src={people} alt="" />
                </div>

                <>
                  <img src={arrow} className="img-fluid" id="arrow3" alt="" />
                </>

                <select
                  className="form-control py-3 rounded-0 shadow"
                  name="people"
                >
                  <option className="people" value="people">
                    Number of People
                  </option>
                  <option className="text-dark" value="saab">
                    1
                  </option>
                  <option className="text-dark" value="opel">
                    2
                  </option>
                  <option className="text-dark" value="audi">
                    3
                  </option>
                </select>
              </div>
            </div>
            <div className="text-center">
              <input
                type="submit"
                value="Submit"
                className="submit py-3 rounded-0 mt-2 px-5 shadow"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
