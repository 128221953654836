import React from "react";
import { Helmet } from "react-helmet-async";
const Yoga = () => {
  return (
    <div className="container">
      <Helmet>
        <title>
          Importance of Yoga | Positive Table Tennis Academy in Panchkula
        </title>
      </Helmet>
      
      <div className="yoga-heading my-auto">
        <div className="header mt-5 ">
        {/*<img src={yogaimg} className="img-fluid"  width="100%" height="100%" alt="" /> */}
        <h3>
            <span className="text-uppercase"> Importance of Yoga </span>
            <br />
            Reasons Why Everyone Must Do Yoga{" "}
          </h3>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="YogaDescription" style={{ fontSize: "20px" }}>
          <p>
          Yoga as a lifestyle is that holistic way of living your life that can
          change the way you handle life significantly. Practicing yoga creates
          a deeper sense of knowing and enables to create a union with something
          higher than an individual’s consciousness.
          </p>
        </div>
        <div style={{ fontSize: "20px" }}>
          <p>
            Yoga as a lifestyle is that holistic way of living your life that
            can change the way you handle life significantly. Practicing yoga
            creates a deeper sense of knowing and enables to create a union with
            something higher than an individual’s consciousness.
          </p>
          <p>
            Adopting yoga as a lifestyle is like a tool that helps a person be
            in a blissful state of being. The people who practice and deepen
            their knowledge ofyoga easily become capable of dealing with almost
            anything life throws at them. They begin to realize that their will
            has power over anything in their lives.
          </p>
          <p>
            It builds discipline, self-discipline. Intimate understanding of
            your body – With gradual practice, you start listening to your body.
            You become aware of the resistance in certain muscles or asanas that
            come naturally to you. Understanding the teachings – Certain yoga
            postures take time and practice. And self practice gives you the
            best opportunity.
          </p>
          <h3 className="text-warning">
            Here are some of the common health benefits of practising yoga:
          </h3>
          <ul>
            <li> Helps reduce stress</li>
            <li>Relieves anxiety</li>
            <li>Improves concentration levels</li>
            <li>Helps reduce inflammation</li>
            <li>Improves quality of life</li>
            <li>Could reduce chronic pain</li>
            <li>Promotes quality of sleep</li>
          </ul>
          <p>
            Yoga with its many health benefits changes you for the better into a
            fitter and happier person.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Yoga;
