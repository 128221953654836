import React from "react";
import { Helmet } from "react-helmet-async";

const TableTennis = () => {
  return (
    <>
      <Helmet>
        <title>
          Importance of Table Tennis | Positive Table Tennis Academy |
          Professional table tennis coaching in Panchkula, Chandigarh.
        </title>
      </Helmet>
      <div className="container">
        <div className="tennis-heading">
          <div className="header">
          <h3>
            <span className="text-uppercase"> Importance of TABLE TENNIS </span>
          </h3>
          </div>
        </div>
        <div className="row align-items-center">
          {/*<div className="col-1 pe-1 me-0 display-1 text-end">S</div>*/}
          <div className="TableTennisDescription" style={{ fontSize: "20px" }}>
              <p>
              Sports have been viewed as a way to stay healthy and fit. But
              sports’ importance goes much further. When started at a young
              age, sports help in the overall development of students.
              Playing sports teaches lessons in life such as teamwork,
              accountability, self-confidence, responsibility, and
              self-discipline. Sports in school help prepare students to
              face the challenges of life. They enhance the physical and
              mental abilities of students and help them achieve the goals
              of their lives.
              </p>
            </div>
              
            
          <div style={{ fontSize: "20px" }}>
          <p>
            Table tennis, also called (trademark) Ping-Pong, ball game similar in
            principle to lawn tennis and played on a flat table divided into two
            equal courts by a net fixed across its width at the middle. The
            object is to hit the ball so that it goes over the net and bounces
            on the opponent’s half of the table in such a way that the opponent
            cannot reach it or return it correctly.
            </p>
            <p>
              A match consists of the best of any odd number of games, each game
              being won by the player or team who first reaches 11 points or
              who, after 10 points each, gains a two-point lead.
            </p>
            <p>
              Many healthcare specialists note that table tennis helps to
              strengthen the cardiovascular system and brings pressure back to
              normal. They recommend this sport as prophylaxis for heart attacks
              and strokes. Table tennis is referred to as aerobic exercise,
              which increases blood circulation, has a positive effect on the
              work of the heart, and increases the body’s endurance.
            </p>
            <p>
              Table tennis is not only a great workout for the muscles of the
              body but also a great workout for the eyes. Constant concentration
              on the ball, which is moving away, approaching, then slowing down,
              then accelerating, helps to relieve stress and fatigue, as well as
              maintain excellent vision.{" "}
            </p>
            <p>
              Playing table tennis regularly helps to strengthen the muscles in
              the legs, arms, back, and abs. Also, playing table tennis uses the
              various joints of the arm, legs, and torso. As a result, the
              joints become more flexible, which allows you to maintain physical
              activity until old age.
            </p>
            <h3 className="text-warning">
              Here are some of the common health benefits of Table Tennis:
            </h3>
            <ul>
              <li>Playing improves hand-eye coordination </li>
              <li>Develops mental acuity</li>
              <li>Improves concentration levels</li>
              <li>It’s easy on the joints</li>
              <li>Improves quality of life</li>
              <li>Stimulates various different parts of the brain</li>
              <li>Improves Balnce</li>
            </ul>
            <p>
              Table tennis with its many health benefits changes you for the
              better into a fitter and happier person.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableTennis;
