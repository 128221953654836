import React from "react";
import arrow from "./../Assets/icons/arrow2.png";

const BannerCard = ({ header, topColor, bottomColor, description }) => {
  return (

    <a className="mb-5 text-white px-lg-3 py-lg-4 py-2 rounded-3 d-flex flex-column justify-content-between" id="Bannercardss"
    style={{
      backgroundImage: `linear-gradient(to bottom, ${topColor}, ${bottomColor})`,
      width: "100%",
    }} href="#contact">
    <div>
      <h3
        className={`fw-bolder ${
          header === "What Level Is My Child?"
            ? "col-9"
            : header === "Register For Yoga Session"
            ? "col-10"
            : ""
        }`}
        style={{ marginBottom: 20 }}
      >
        {header}
      </h3>

      <div className="row align-items-baseline">
        <p className="col-9 mt-auto"></p>
        <a href="#contact" className="col-3 ms-auto mt-auto">
          <img src={arrow} className="arrowimg img-fluid" alt="" />
        </a>
      </div>
    </div>
    </a>
  );
};


export default BannerCard;
