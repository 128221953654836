import React from "react";
import { useNavigate } from "react-router-dom";
import practice from "./../Assets/videos/Practice.mp4";
import yoga from "./../Assets/videos/Yoga_with musicfinal.mp4";

const Importance = () => {
  const navigate = useNavigate();
  const goToTabTen = () => {
    window.scrollTo(0, 0);
    navigate("/importance-of-table-tennis");
  };

  const goToYogLes = () => {
    window.scrollTo(0, 0);
    navigate("/importance-of-yoga-lesson");
  };

  return (
    <div className="container-fluid px-5" id="importance">
      <div className="row p-lg-0">
        <div className="col-12 col-lg-6 pt-1 pb-0 bg-light d-flex align-items-center order-1 order-lg-0">
          <div className="my-auto px-lg-5 order-1 order-lg-0">
            <h3 className="fw-bolder">Importance of Table Tennis lessons</h3>
            <p>
              Playing table tennis enhances hand-eye coordination while also
              increasing mental awareness, concentration, and tactical thinking.
              This makes it an ideal game for young people to hone their
              reflexes. Table tennis relies heavily on the speed, spin, and
              location of the ball, and strong players are adept at both
              designing and solving puzzles involving these three
              characteristics. Because of the sport's fast-paced, short-distance
              character, both gross and fine muscular motions are improved.
            </p>
            <div className="pt-3">
              <button onClick={goToTabTen} className="btn-gradient">
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 pt-2 pt-lg-0 mt-0">
          <video controls width="100%" height="500px">
            <source src={practice} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="row my-4 mt-3 p-lg-0 align-items-top">
        <div className="col-12 col-lg-6 p-2 p-lg-0">
          <video controls width="100%" height="auto">
            <source src={yoga} type="video/mp4" />
          </video>
        </div>
        <div className="col-12 col-lg-6">
          <div className="bg-light">
            <div className="my-auto p-lg-5 py-lg-4 order-1 order-lg-0">
              <h3 className="fw-bolder">Importance of Yoga lessons</h3>
              <p>
                Along with table tennis, we also offer yoga classes for the
                mental well-being of our players. Players are required to twist,
                turn, lunge, jump, run, stretch, and bend quickly and suddenly.
                Sometimes, even awkwardly. This increases the chances of a wrong
                step. Enter yoga to the rescue!
              </p>
              <p>
                Yoga improves your balance and teaches you to be poised and
                disciplined. It makes your body fit enough to perform these
                moves. It also trains your mind to be patient and steady.
              </p>
              <div className="pt-3 pb-4">
                <button onClick={goToYogLes} className="btn-gradient">
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Importance;
