import React from "react";
import phone from "./../Assets/icons/phone.png";
import facebook from "./../Assets/icons/facebook.png";
import instagram from "./../Assets/icons/instagram2.png";
import whatsapp from "./../Assets/icons/whatsapp.png";
import youtube from "./../Assets/icons/youtube.png";

import ReactWhatsapp from "react-whatsapp";

const Contact = () => {
  return (
    <div className="overflow-x-auto px-5 my-5 mx-2" id="contact">
      <div className="row">
        <div className="bg-light d-flex align-items-center col-12 col-lg-5">
          <div className="fw-bold">
            <h2 className="contactid fw-bold mt-4">Contact Us</h2>

            <div className="py-2">
              <p>
                Centre: Sco 1, Village Kundi, Sector 20, Panchkula, Haryana
                134117
              </p>
            </div>
            <div className="row align-items-center ps-2">
              <div className="col-2 col-lg-1">
                <img src={phone} alt="" className="img-fluid" />
              </div>
              <div className="phoneid col-10 col-lg-11">
                <>+91-8437188555</>
                <br />
                <>+91-7889003014</>
              </div>
            </div>
            <div className="emailid ps-2 my-4">
              Email:{" "}
              <a href="mailto:info@positivetabletennis.com">
                info@positivetabletennis.com{" "}
              </a>
            </div>
            <div className="Operatinghrs ps-2 my-4">
              (Operating Hours 5 am to 8 pm)
            </div>

            <div className="row row-cols-3 ms-0 col-lg-4 col-8 ps-0 align-items-center mb-4">
              <a
                href="https://www.facebook.com/tabletennisacademypkl/"
                className="mx-0 "
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="img-fluid mx-2 ms-0"
                  src={facebook}
                  id="facebook"
                  alt=""
                />
              </a>

              <a
                href="https://www.instagram.com/positivetabletennis/?hl=en"
                className="mx-0"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img className="img-fluid mx-2 ms-0" src={instagram} alt="" />
              </a>

              <ReactWhatsapp
                number="+918437188555"
                className="mx-0 bg-transparent border-0 rounded-circle"
              >
                {" "}
                <img
                  className="img-fluid mx-2 ms-0"
                  src={whatsapp}
                  id="whatsapp"
                  alt=""
                />
              </ReactWhatsapp>
            </div>
            <div className="flex flex-col g-2">
              <a
                href="https://www.youtube.com/@tabletenniszone"
                className="mx-0 flex g-2"
                style={{paddingLeft:"10px",alignItems:"center"}}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="img-fluid mx-2 ms-0"
                  src={youtube}
                  alt=""
                  style={{width:"30px",height:"30px"}}
                />
                <p style={{margin:0,padding:0}}>Table Tennis Zone</p>
              </a>

              <a
                href="https://www.instagram.com/positivetabletennis/?hl=en"
                className="mx-0 flex g-2"
                style={{paddingLeft:"10px",alignItems:"center"}}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  style={{width:"30px",height:"30px"}}
                  className="img-fluid mx-2 ms-0"
                  src={youtube}
                  alt=""
                />
                <p style={{margin:0,padding:0}}>Positive table tennis academy</p>
              </a>
            </div>
          </div>
        </div>

        <div className="px-0 ps-lg-3 col-12 col-lg-7">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6863.8562348742535!2d76.8464051!3d30.6641526!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f955e5dba612b%3A0x878a26c98ddd6d33!2sPositive%20Table%20Tennis%20Academy%20and%20Yoga%20studio!5e0!3m2!1sen!2sbd!4v1664620050422!5m2!1sen!2sbd"
                width="100%"
                height="500"
                id="gmap_canvas"
                frameBorder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                title="Positive Table Tennis Academy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
