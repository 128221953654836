import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Navbar from "./components/Navbar";
import Yoga from "./Pages/Yoga";
import TableTennis from "./Pages/TableTennis";
import Register from "./Pages/Register";
import About from "./Pages/About";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/importance-of-yoga-lesson" element={<Yoga />}></Route>
        <Route
          path="/importance-of-table-tennis"
          element={<TableTennis />}
        ></Route>
        <Route path="/register" element={<Register></Register>}></Route>
        <Route path="/about" element={<About></About>}></Route>
      </Routes>
    </BrowserRouter>
  );
  
}

export default App;
